
.actions-container {
	display: flex;
	flex: 1;
	justify-content: center;
	overflow: hidden;
}
.actions-inside {
	overflow-y: auto;
	padding: 0px 63px;


    word-break: break-all;
    margin-bottom: 200px;
    margin-bottom: 31px;
    max-width: 800px;
}
