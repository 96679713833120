.final-score-right {
    flex: 0.7;
    position:relative;
    display: flex;
    flex-direction: column;    

    .topbar {
        z-index: 1;
    }
    .final-score-content {
        overflow: auto;
        flex: 1;
    }
	.final-score-right-inside {
        display: flex;
		flex: 1;
		flex-direction: column;
		align-items: center;
        justify-content: center;
        padding-top: 100px;
        overflow: hidden;

        position: relative;
        .canvas-container {
            position: absolute;
            top: 150px;
            left: 0px;
            width: 99%;
            // border: 1px solid black;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        #confetti-canvas {
            // border: 1px solid red;
        }
        .inside-donut {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            height: 100%;
        }
    }
}