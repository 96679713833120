.login {
	display: flex;
	flex: 0.5;
	.login-form {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.login-form-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
		min-height: 500px;
		.login-logo {
			margin-bottom: 50px;
		}

		.name-input-container {
			display: flex;
			flex-direction: column;
			max-width: 820px;
		}

		.others-input-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			.room {
				margin-right: 16px; 
			}
			.error {
				margin-right: 16px;
			}
		}

		input {
			min-width: 200px;
		}
	}
}
.start-button {

}
