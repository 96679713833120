.inventory {

	.inventory-right {
		flex: 0.7;
		position:relative;
		display: flex;
		flex-direction: column;
		padding-bottom: 120px;

		.inventory-right-content {
			display: flex;
			flex-direction: column;
			height: 100vh;
		}
	}
	.inventory-right-inside {
		padding-top: 100px;
		padding-bottom: 290px;
		overflow-y: auto;
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: center;
		justify-content: stretch;
		.inventory-title {
			padding: 24px 64px;
			text-align: left;
		}
		.inventory-selector {
			flex: 1;
			max-width: 700px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

		}

	input {
		width: 600px;
	}
	}
}
