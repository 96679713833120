@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://p.typekit.net/p.css?s=1&k=von4jpa&ht=tk&f=39494.39495.39496.39497.39498.39499.39500.39501.39504.39505.39506.39507.39508.39509&a=39865428&app=typekit&e=css");
 
 @font-face {
 font-family:"neue-haas-grotesk-display";
 src:url("https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
 font-display:auto;font-style:normal;font-weight:400;
 }
 
 
 @font-face {
   font-family:"lust";
   src:url("./tokens/fonts/Lust\ Slim\ Italic.ttf") format("ttf");
   font-display:auto;font-style:normal;font-weight:400;
 }
 
 
 @font-face {
 font-family:"neue-haas-grotesk-display";
 src:url("https://use.typekit.net/af/2b59e1/00000000000000007735bb53/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/2b59e1/00000000000000007735bb53/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/2b59e1/00000000000000007735bb53/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("opentype");
 font-display:auto;font-style:italic;font-weight:400;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-display";
 src:url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("opentype");
 font-display:auto;font-style:normal;font-weight:500;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-display";
 src:url("https://use.typekit.net/af/6c4da4/00000000000000007735bb5e/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/6c4da4/00000000000000007735bb5e/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/6c4da4/00000000000000007735bb5e/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("opentype");
 font-display:auto;font-style:italic;font-weight:500;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-display";
 src:url("https://use.typekit.net/af/153042/00000000000000007735bb62/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/153042/00000000000000007735bb62/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/153042/00000000000000007735bb62/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("opentype");
 font-display:auto;font-style:normal;font-weight:600;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-display";
 src:url("https://use.typekit.net/af/8a7571/00000000000000007735bb67/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/8a7571/00000000000000007735bb67/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/8a7571/00000000000000007735bb67/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("opentype");
 font-display:auto;font-style:italic;font-weight:600;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-display";
 src:url("https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("opentype");
 font-display:auto;font-style:normal;font-weight:700;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-display";
 src:url("https://use.typekit.net/af/fcc1c9/00000000000000007735bb6c/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/fcc1c9/00000000000000007735bb6c/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/fcc1c9/00000000000000007735bb6c/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("opentype");
 font-display:auto;font-style:italic;font-weight:700;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-text";
 src:url("https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
 font-display:auto;font-style:normal;font-weight:400;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-text";
 src:url("https://use.typekit.net/af/aed66e/00000000000000007735bb35/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/aed66e/00000000000000007735bb35/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/aed66e/00000000000000007735bb35/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("opentype");
 font-display:auto;font-style:italic;font-weight:400;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-text";
 src:url("https://use.typekit.net/af/160664/00000000000000007735bb32/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/160664/00000000000000007735bb32/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/160664/00000000000000007735bb32/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("opentype");
 font-display:auto;font-style:normal;font-weight:500;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-text";
 src:url("https://use.typekit.net/af/fe63ce/00000000000000007735bb4b/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/fe63ce/00000000000000007735bb4b/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/fe63ce/00000000000000007735bb4b/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("opentype");
 font-display:auto;font-style:italic;font-weight:500;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-text";
 src:url("https://use.typekit.net/af/305037/00000000000000007735bb39/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/305037/00000000000000007735bb39/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/305037/00000000000000007735bb39/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("opentype");
 font-display:auto;font-style:normal;font-weight:700;
 }
 
 @font-face {
 font-family:"neue-haas-grotesk-text";
 src:url("https://use.typekit.net/af/68a7c6/00000000000000007735bb3d/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/68a7c6/00000000000000007735bb3d/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/68a7c6/00000000000000007735bb3d/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("opentype");
 font-display:auto;font-style:italic;font-weight:700;
 }
 
 .tk-neue-haas-grotesk-display { font-family: "neue-haas-grotesk-display",sans-serif; }
 .tk-neue-haas-grotesk-text { font-family: "neue-haas-grotesk-text",sans-serif; }
 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  color:#0B1F2F
 }
 
 
 @keyframes App-logo-spin {
   from {
     transform: rotate(0deg);
   }
   to {
     transform: rotate(360deg);
   }
 }
 
 /*
  * The Typekit service used to deliver this font or fonts for use on websites
  * is provided by Adobe and is subject to these Terms of Use
  * http://www.adobe.com/products/eulas/tou_typekit. For font license
  * information, see the list below.
  *
  * neue-haas-grotesk-display:
  *   - http://typekit.com/eulas/00000000000000007735bb48
  *   - http://typekit.com/eulas/00000000000000007735bb53
  *   - http://typekit.com/eulas/00000000000000007735bb5a
  *   - http://typekit.com/eulas/00000000000000007735bb5e
  *   - http://typekit.com/eulas/00000000000000007735bb62
  *   - http://typekit.com/eulas/00000000000000007735bb67
  *   - http://typekit.com/eulas/00000000000000007735bb6a
  *   - http://typekit.com/eulas/00000000000000007735bb6c
  * neue-haas-grotesk-text:
  *   - http://typekit.com/eulas/00000000000000007735bb33
  *   - http://typekit.com/eulas/00000000000000007735bb35
  *   - http://typekit.com/eulas/00000000000000007735bb32
  *   - http://typekit.com/eulas/00000000000000007735bb4b
  *   - http://typekit.com/eulas/00000000000000007735bb39
  *   - http://typekit.com/eulas/00000000000000007735bb3d
  *
  * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
  */
 /*{"last_published":"2021-01-20 11:24:07 UTC"}*/
 