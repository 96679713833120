.inventory-overview {
	.inventory-overview-right {
		flex: 0.7;
		position: relative;
		display: flex;
		flex-direction: column;
		padding-bottom: 120px;


		.inventory-middle {
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			align-items: center;
			flex: 1;
			padding: 10% 10% 100px 10%;
		}
		.inventory-overview-right-content {
			display: flex;
			flex-direction: column;
			height: 100vh;
			overflow: hidden;
		}
		.inventory-overview-right-inside {
			flex: 1;
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}
	.inventory-overview-middle {
		padding-top: 64px;
		display: flex;
    	flex: 1;
		justify-content: center;
	}
}
