.axis {

	.domain {
		display: none;

	}
	.tick {
		line {
			display: none;
		}
		text {


		font-family: "neue-haas-grotesk-display";
		font-size: 12px;
	}
	}
}
