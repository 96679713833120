.picker-horizontal {
	display: flex;
	flex-direction: column;
	flex: 0.7; 
	.business-picker-select {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
.business-after-selection {
	position: relative;
	flex: 0.7;
	display: flex;
	flex-direction: column;

	.business-after-container {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
	}
	.business-after-selection-inside {
		display: flex;
		text-align: left;
		flex-direction: column;
		> * {
			display: flex;
			justify-content: flex-start;
		}
		.instruction {
			margin: 12px 0px;
		}
	}
}
