.marketing-after-selection {
	position: relative;
	flex: 0.7;
	display: flex;
	flex-direction: column;
	.marketing-after-selection-content {
		// overflow-y: auto;
		height: 100vh;
		display: flex;
		flex-direction: column;
		// padding-bottom: 200px;
	}
	
}

.marketing-picker-select-inside {
	display: flex;
	flex-direction: column;
}
.marketing-after-selection-inside {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	flex: 1 1;
	padding: 10% 5% 200px 5%;

	.marketing-after-selection-container {
		flex: 1;
		display: flex;
		justify-content: center;
		text-align: left;
		align-items: center;
	}
}
.marketing-duration-subtitle {
	margin: 8px 0px 16px 0px;
}
.marketing-cards-container {
	display: flex;
	max-width: 100%;
	// flex: 1;
	justify-content: center;
}
.picker-horizontal {
	display: flex;
	flex-direction: column;
	flex: 0.7;	
    overflow-y: auto;
    overflow-x: hidden;
	.business-picker-select {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}