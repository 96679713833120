.location-right {
	position: relative;
	flex: 0.7;
	height: 100%;
	overflow: hidden;
	.location-right-inside {
		overflow-y: auto;
		display: flex;
		justify-content: center;
		flex-direction: column;
		height: 100%;

		.location-content {
			display: flex;
			justify-content: center;
			overflow: hidden;
		}
	}
}
