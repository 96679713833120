
.finantial-overview {

	.finantial-overview-right {
		flex: 0.7;
		position:relative;
		display: flex;
	}
	.finantial-overview-right-inside {
		display: flex;
		flex: 1;
		flex-direction: column;
		padding: 3% 10% 180px 10%;
		overflow: auto;


		.financial-header {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			
			text-align: left;
			> * {
				margin: 12px 0px;
			}
		}
	}
	.financial-overview-cards-container {
		margin-top: 100px;
		display: flex;

	}
}
