.price-markdown {
	.price-markdown-right {
		flex: 0.7;
		position: relative;
		display: flex;
		flex-direction: column;
		.price-markdown-content {
			display: flex;
			flex-direction: column;
			height: 100vh;
		}

		.price-markdown-middle {
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			flex: 1;
			padding: 10% 10% 200px 10%;
		}
	}
	.inventory-overview-middle {
		display: flex;
    	flex: 1;
		justify-content: center;
		margin-top: 60px;
	}
}
