.page {
	display: flex;
	height: 100vh;
	overflow: hidden;
}
.left {
	.svg-bg {
		position: absolute;
		bottom: 0px;
		// left: 0px;
	}
}
.center-right {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.major-right {
	flex: 0.7;
	position: relative;
}
.banner {
	width: 100%;
}
	.progress-bar-container {
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		z-index: 1;
	}
