.welcome-container-information {
	min-width: 600px;
	max-width: 800px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: justify;
}
.welcome-right {
	display: flex;
	flex-direction: column;
	.welcome-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 2% 10%;

		.instruction {
			margin-top: 32px;
		}
	}
}
